import React from 'react'

interface LoaderProps {
  small?: boolean
}

const Loader = (props: LoaderProps) => (
  <div className={`loader ${props.small && 'loader-small'}`}>
    <div className='loader-spinner'></div>
  </div>
)

export default Loader
