'use client'

import { motion } from 'framer-motion'
import { FunctionComponent, ReactNode, useContext, useEffect } from 'react'
import { ModalContext, ModalContextType } from '../../context/ModalContext'
import Icon from '../Icon/Icon'
import { Icons } from '../Icon/constants'
import { useTranslation } from '../../context/ConfigContext'

interface ModalProps extends ModalInterface {
  dark?: boolean
  showLogo?: boolean
  children: ReactNode
  center?: boolean
  anchor?: 'left' | 'right' | 'center'
  title?: string
  className?: string
}

export const Modal: FunctionComponent<ModalProps> = ({
  className,
  uuid,
  dark = true,
  showLogo = true,
  onClose,
  children,
  center = false,
  anchor = 'left',
  title,
}) => {
  const { modals, removeModal } = useContext(ModalContext) as ModalContextType

  const handleClose = () => {
    onClose && onClose()
    removeModal(uuid)
    document.body.classList.remove('contrast-modal-open')
  }

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onClose && onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  useEffect(() => {
    document.body.classList.add('no-overflow')
    return () => {
      if (modals.length === 1) {
        document.body.classList.remove('no-overflow')
      }
    }
  }, [])

  const t = useTranslation()

  return (
    <div className={`modal-overlay modal-${anchor} ${className ?? ''}`} key={uuid}>
      <motion.section
        className={`modal ${dark ? 'dark' : 'light'}`}
        style={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        role='dialog'
        aria-modal='true'>
        <div className='header-modal'>
          <button
            type='button'
            className={`close ${dark && 'contrast'}`}
            onClick={handleClose}
            autoFocus
            aria-label={t('Close', {}, false)}>
            <Icon iconName={Icons.CLOSE} />
          </button>
          {showLogo && (
            <picture className='messika-logo'>
              <Icon iconName={Icons.MESSIKA_LOGO_WHITE} width={114} height={32} />
            </picture>
          )}
          {title && <h4 className='title-h4'>{title}</h4>}
        </div>
        <div className={center ? 'center modal-content' : 'modal-content'}>{children}</div>
      </motion.section>
      <div className='overlay' onClick={handleClose} tabIndex={-1}></div>
    </div>
  )
}
