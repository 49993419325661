interface ButtonProps {
  primary?: boolean
  backgroundColor?: string
  classname?: string
  size?: 'small' | 'medium' | 'large'
  label: string
  href?: string
  onClick?: () => void
  style?: 'dark' | 'light' | 'very-light' | 'light-clear'
  disabled?: boolean
  type?: 'button' | 'submit'
}

export const Button = ({
  /* eslint-disable-next-line */
  primary = false,
  /* eslint-disable-next-line */
  size = 'small',
  /* eslint-disable-next-line */
  backgroundColor,
  classname,
  label,
  /* eslint-disable-next-line */
  style,
  type = 'button',
  ...props
}: ButtonProps) => {
  const Component = props.href ? 'a' : 'button'
  return (
    <Component
      type={type}
      className={['button', `${classname}`].join(' ')}
      style={{ backgroundColor }}
      {...props}>
      {label}
    </Component>
  )
}
